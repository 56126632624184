import { HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'
import { setWithExpiry, getWithExpiry } from '../../helpers/storage'
import { isEmpty, size } from 'lodash'

export const actionTypes = {
    DELIVERY_CHANGE_AUTH: 'DELIVERY_CHANGE_AUTH',
    DELIVERY_SUCCESS_AUTH: 'DELIVERY_SUCCESS_AUTH',
    DELIVERY_ORDERS: 'DELIVERY_ORDERS',
    DELIVERY_USER_INFO: 'DELIVERY_USER_INFO',
    DELIVERY_ORDER_INFO: 'DELIVERY_ORDER_INFO',
    DELIVERY_ACCEPT_ORDER: 'DELIVERY_ACCEPT_ORDER',
    DELIVERY_PICKEDUP_ORDER: 'DELIVERY_PICKEDUP_ORDER',
    DELIVERY_DELIVER_ORDER: 'DELIVERY_DELIVER_ORDER',
    DELIVERY_CHANGE_PLAYSONG: 'DELIVERY_CHANGE_PLAYSONG',
}

export const changeAuth = (payload) => ({
    type: actionTypes.DELIVERY_CHANGE_AUTH,
    payload
})

export const successAuth = (payload) => ({
    type: actionTypes.DELIVERY_SUCCESS_AUTH,
    payload
})

export const setUserToken = (token) => dispatch => {
    localStorage.setItem('access_token', token);
    dispatch( changeAuth({
        email: '',
        password: ''
    }) )

    dispatch( successAuth(true) )
}

export const setUserId = (user_id) => dispatch => {
    localStorage.setItem('user_id', user_id);
    dispatch( changeAuth({
        email: '',
        password: ''
    }) )

    dispatch( successAuth(true) )
}

export const setUserInfo = (data) => dispatch => {
    localStorage.setItem('user_name', data.name);
    localStorage.setItem('user_phone', data.phone);
    localStorage.setItem('user_email', data.email);
}

export const login = credentials => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Autenticando usuário...'
    }))

    return HttpAuth.post('delivery/login', {
        email: credentials.email,
        password: credentials.password,
    })
    .then(res => {
        dispatch( changeLoading( { open: false } ) );
        if(typeof res !== 'undefined') {
            if(res.data.data.auth_token) {
                dispatch( setUserToken( res.data.data.auth_token ) );
                dispatch( setUserId( res.data.data.id ) );
                dispatch( setUserInfo( res.data.data ) );
            }
        }
    })
    .catch(error => {
        dispatch( changeLoading( { open: false } ) );
        if(typeof error.response !== 'undefined') {
            if(error.response.state === 401 || error.response.state === 400) {
                dispatch( dispatch( changeNotify( { 
                    open: false,
                    classe: 'error',
                    msg: 'E-mail ou Senha incorretos'
                } ) ) )
            }
        } else {
            dispatch( dispatch( changeNotify( { 
                open: false,
                classe: 'error',
                msg: 'Erro ao se conectar ao servidor'
            } ) ) )
        }
    })
} 

export const indexResponse = (payload) => {
    return {
        type: actionTypes.DELIVERY_ORDERS,
        payload,
    }
}

export const indexResponseSingleOnder = (payload) => {
    return {
        type: actionTypes.DELIVERY_ORDER_INFO,
        payload,
    }
}

export const changePlaysong = (payload) => ({
    type: actionTypes.DELIVERY_CHANGE_PLAYSONG,
    payload
})


export const indexOrders = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Buscando pedidos...'
    }));

    const rota = "get-delivery-orders";

    return HttpAuth.post(rota, userData)
            .then(res => {
                if(typeof res !== 'undefined') {
                    dispatch(indexResponse(res.data));
                    dispatch(changeLoading({open: false}));
                    
                    if( isEmpty( getWithExpiry("orders_old") ) ) {
                        setWithExpiry("orders_old", res.data);
                    } else {
                        if( size( getWithExpiry("orders_old").new_orders ) < size( res.data.new_orders ) ) {
                            dispatch(changePlaysong({
                                playsong: true
                            }));
                            setWithExpiry("orders_old", getWithExpiry("orders_new"));
                        } else {
                            dispatch(changePlaysong({
                                playsong: false
                            }));
                        }
                    }
                }
            })
            .catch(err => {return err;})
}

export const userInfoResponse = (payload) => {
    return {
        type: actionTypes.DELIVERY_USER_INFO,
        payload,
    }
}

export const userInfo = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Buscando informações...'
    }));

    const rota = "update-user-info";

    return HttpAuth.post(rota, userData)
            .then(res => {
                if(typeof res !== 'undefined') {
                    dispatch(userInfoResponse(res.data));
                    dispatch(changeLoading({open: false}))
                }
            })
            .catch(err => {return err;})
}

export const indexSingleOrder = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Buscando pedido...'
    }));

    const rota = "get-single-delivery-order";

    return HttpAuth.post( rota, userData )
            .then( res => {
                if(typeof res !== 'undefined') {
                    dispatch( indexResponseSingleOnder(res.data) );
                    dispatch( changeLoading({open: false}) )
                }
            } )
            .catch(err => {return err;})
}

export const indexAcceptToOnder = (payload) => {
    return {
        type: actionTypes.DELIVERY_ACCEPT_ORDER,
        payload,
    }
}

export const acceptToOrder = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Aceitando pedido...'
    }));

    const rota = "accept-to-deliver";

    return HttpAuth.post( rota, userData )
            .then( res => {
                if(typeof res !== 'undefined') {
                    dispatch( indexAcceptToOnder(res.data) );
                    dispatch( changeLoading({open: false}) )
                }
            } )
            .catch(err => {return err;})
}

export const indexPickedUpOnder = (payload) => {
    return {
        type: actionTypes.DELIVERY_PICKEDUP_ORDER,
        payload,
    }
}

export const pickedUpOrder = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Entregando pedido...'
    }));

    const rota = "pickedup-order";

    return HttpAuth.post( rota, userData )
            .then( res => {
                if(typeof res !== 'undefined') {
                    dispatch( indexPickedUpOnder(res.data) );
                    dispatch( changeLoading({open: false}) )
                }
            } )
            .catch(err => {return err;})
}

export const indexDeliverOnder = (payload) => {
    return {
        type: actionTypes.DELIVERY_DELIVER_ORDER,
        payload,
    }
}
export const deliverOrder = (userData) => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Informando...'
    }));

    const rota = "deliver-order";

    return HttpAuth.post( rota, userData )
            .then( res => {
                if(typeof res !== 'undefined') {
                    dispatch( indexDeliverOnder(res.data) );
                    dispatch( changeLoading({open: false}) )
                }
            } )
            .catch(err => {return err;})
}
