import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import './index.css';
import './global.css';
import AppRoutes from './routes';

import { Loading, Notify, Alert } from './view/components';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500]
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true,
    },
    MuiSelect: {
      variant: 'outlined',
      fullWidth: true,
    }
  }
});

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
        <Loading />
        <AppRoutes />
    </ThemeProvider>
  </Provider>
)

export default App;
